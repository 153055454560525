import React from "react"
// import { Link } from "gatsby"
import { connect, useSelector } from "react-redux"

import Layout from "../components/layout"
import ContentBasic from "../components/content-basic"
import { graphql } from "gatsby"
import get from "lodash/get"
// import { node } from "prop-types"

// import { IMAGES } from '../../constants';

export const query = graphql`
  query MyQuery($uid: String) {
    allPrismicBasicContentPage(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        id
        data {
          page_under
          banner_image {
            url
          }
          banner_title {
            text
          }
          page_description {
            type
            text
            spans {
              end
              start
              type
              data {
                link_type
                url
              }
            }
          }
          page_heading {
            text
          }
          show_breadcrumb
          show_buttons
          show_stats
          show_subscription_panel
          show_risk_heading
          body {
            items {
              field_file {
                url
                link_type
                kind
              }
              field_text {
                text
                type
              }
            }
            slice_type
          }
          stats_replacement_text {
            text
          }
        }
      }
    }
  }
`

const Careers = props => {
  const nodes = get(props.data, "allPrismicBasicContentPage.nodes", [])
  const data = get(nodes, "0.data")
  if (!data) {
    return null
  }
  return (
    <>
      <Layout>
        <ContentBasic {...data} />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(Careers)
