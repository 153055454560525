export function tableDataMapper(table = []) {
  const tableHeader = []
  const tableBody = []
  table.forEach((data, index) => {
    if (index === 0) {
      data.items.map(item => {
        tableHeader.push(item.field_text[0].text)
        return tableHeader
      })
    } else {
      const body = []
      data.items.map(item => {
        let obj = {
          text: item.field_text[0] ? item.field_text[0].text : "",
          file_url: item.field_file.url,
          file_kind: item.field_file.kind,
        }

        body.push(obj)
        return body
      })
      tableBody.push(body)
    }
    return true
  })

  return [tableHeader, tableBody]
}
