import React from "react"
// import { Link } from "gatsby"

import {
  HeadingBanner,
  BreadCrumbBanner,
  QuickStats,
  SubscriptionPanel,
  MainContent,
  ContentTable,
  QuickStatsText,
} from "./../shared"
import { tableDataMapper } from "../../prismic/helpers"

const ContentBasic = params => {
  const {
    banner_image,
    banner_title,
    page_description,
    page_heading,
    page_under,
    show_breadcrumb,
    show_buttons,
    show_risk_heading,
    show_stats,
    show_subscription_panel,
    body,
    stats_replacement_text,
  } = params
  const tableSlices = body.filter(obj => obj.slice_type === "table")
  let tableHeader, tableBody
  if (tableSlices.length > 0) {
    ;[tableHeader, tableBody] = tableDataMapper(tableSlices)
  }
  return (
    <>
      {show_breadcrumb === false && (
        <HeadingBanner
          heading={banner_title}
          banner_image={banner_image}
          show_breadcrumb={show_breadcrumb}
          show_buttons={show_buttons}
          show_risk_heading={show_risk_heading}
        />
      )}
      {show_breadcrumb === true && (
        <BreadCrumbBanner heading={banner_title} banner_image={banner_image} />
      )}
      {show_stats === true && <QuickStats />}
      {show_stats === false && stats_replacement_text[0] !== undefined && (
        <QuickStatsText heading={stats_replacement_text} />
      )}
      <MainContent
        className={page_under}
        content={page_description}
        heading={page_heading}
      />
      {tableHeader !== null && (
        <ContentTable header={tableHeader} body={tableBody} pageTitle={banner_title[0] && banner_title[0].text || ''}/>
      )}

      {show_subscription_panel === true && <SubscriptionPanel />}
    </>
  )
}

export default ContentBasic
